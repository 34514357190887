import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-5-reshenia-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-5-reshenia-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-5-reshenia-header.jpg"
import LuteinComplexXL from "./../../images/lutein-5-reshenia-header.jpg"

const Lutein5Reshenia = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-reshenia" }}>
    <Seo title="5 решения за силни очи" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>5 РЕШЕНИЯ ЗА СИЛНИ ОЧИ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="5 решения за силни очи"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            5 решения за силни очи
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Продължителната работа пред компютъра води до неприятни последици.
            Новите технологии донесоха със себе си не само постижения, но и
            проблеми, непознати за предишните поколения. Какво всъщност ни
            струва всекидневната работа пред компютъра?
          </p>
          <p>
            Всеки, който прекарва работния си ден пред екрана, познава усещането
            за дискомфорт в очите. Умората им при работа с компютър е на първо
            място сред здравословните проблеми.
          </p>
          <p>
            Най-често срещаните симптоми са: умора и напрежение в очите, сухота,
            зачервяване, чувствителност към светлина, замъглено зрение,
            главоболие.
          </p>
          <p>
            Очните ни мускули трябва да правят упражнения, както всички останали
            мускули, които имаме. За постигане на добри резултати се изисква
            време и отдаденост.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <ol>
            <li>
              Докато сте седнали и отпуснати, вгледайте се в стената в другия
              край на стаята. Без да движите главата си, погледнете вляво и след
              това отново напред, после вдясно и пак напред, нагоре и напред,
              надолу и напред. Задържайте във всяка посока по 1-2 секунди.
            </li>
            <li>
              Седнете и се настанете удобно. Затворете очи и ги покрийте с
              дланите си. Не е нужно да ги притискате, - целта е просто да не
              влиза светлина. Отворете очите си и ги оставете да отпочинат. След
              2-3 минути махнете ръцете си, оставете очите да се адаптират към
              светлината.
            </li>
            <li>
              Затворете очи и ги отпуснете. Масажирайте ги с пръсти, като ги
              движите в кръг, натискайки леко. Правете го около 2 минути.
            </li>
            <li>
              Въртете очите си по посока на и обратно на часовниковата стрелка.
              След като направите завъртането, премигнете няколко пъти и след
              това повторете. Това ще помогне на кръвоснабдяването в очите.
            </li>
            <li>
              Седейки на около 10 см от прозорец, поставете до него малък
              предмет на нивото на очите. Вгледайте се в нещо в далечината за
              10-15 секунди и след това придвижете фокуса към предмета пред
              прозореца. Задръжте на него 10-15 секунди и после отново надалеч.
            </li>
          </ol>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Повтаряйте упражненията, правете ги често – в офиса, вкъщи и дори
            когато сте на почивка.
          </p>
          <p>
            Доказано е, че освен подходящи упражнения, всекидневното хранене на
            очите с необходимите вещества и антиоксиданти спомага за намаляване
            на умората и неприятните усещания в очите.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Lutein5Reshenia
